import {
	SET_USER_DATA,
	CLEAR_USER_DATA,
	SET_SELECTED_BUSINESS
} from '../../redux/actionTypes'


const initial_state = {
	name: '',
	email: '',
	businesses: [
		{
			_id: 1,
			name: 'Greece'
		},
		{
			_id: 2,
			name: 'Penfield'
		}
	],
	currentBusinessId: 1
}

const userReducer = (state = initial_state, action) => {

    switch (action.type) {

		case SET_USER_DATA: {
			return { ...state, ...action.payload};
		}
		case CLEAR_USER_DATA: {
            return { ...state, ...initial_state };
		}

		case SET_SELECTED_BUSINESS: {
			return { ...state, currentBusinessId: action.payload.BusinessId };
		}

        default: return { ...state };
    }

}
export default userReducer
